import { QueryHookOptions } from '@apollo/client'
import {
  DatabaseExportReportInput,
  DatabaseExportReportQuery,
  DatabaseExportReportQueryVariables,
  DatabaseExportReport as DatabaseExportReportType,
} from 'types/graphql'

import type {
  CellFailureProps,
  CellSuccessProps,
  TypedDocumentNode,
} from '@redwoodjs/web'

import DatabaseExportReport from 'src/components/Reports/DatabaseExportReport/DatabaseExportReport'

export const beforeQuery = (
  props: QueryHookOptions<
    DatabaseExportReportQuery,
    { input: DatabaseExportReportInput }
  >
) => {
  return { variables: props, fetchPolicy: 'network-only' }
}

export const QUERY: TypedDocumentNode<
  DatabaseExportReportQuery,
  DatabaseExportReportQueryVariables
> = gql`
  query DatabaseExportReportQuery($input: DatabaseExportReportInput) {
    databaseExportReport: databaseExportReport(input: $input) {
      data
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<DatabaseExportReportQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  databaseExportReport,
  input,
}: CellSuccessProps<
  DatabaseExportReportQuery,
  DatabaseExportReportQueryVariables
> & { input: DatabaseExportReportInput }) => {
  return (
    <DatabaseExportReport
      data={
        databaseExportReport.data as unknown as DatabaseExportReportType['data']
      }
      reportType={input.reportType}
    />
  )
}
