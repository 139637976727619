import { useState } from 'react'

import Button from '@mui/material/Button'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatabaseExportReportType } from 'api/src/enums/enums'
import { sortBy } from 'lodash'
import { usePostHog } from 'posthog-js/react'
import type { Brokerage } from 'types/graphql'

import { Metadata } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import DatabaseExportReportCell from 'src/components/Reports/DatabaseExportReport/DatabaseExportReportCell'
import SelectBrokerages from 'src/components/SelectBrokerages'

const DatabaseExportReportPage = ({ reportType }: { reportType: string }) => {
  const [submitted, setSubmitted] = useState(false)

  const posthog = usePostHog()
  const { profile } = useAuth()
  const [selectedBrokerageIds, setSelectedBrokerageIds] = useState(
    profile?.brokeragesAsAdmin?.map((brokerage: Brokerage) => brokerage.id) ||
      []
  )

  // lookup report type enum using `code` from the route params
  const reportTypeEnum = Object.values(DatabaseExportReportType).find(
    (enumType) => enumType.code === reportType
  )

  return (
    <>
      <Metadata
        title={reportTypeEnum?.name}
        description={`${reportTypeEnum?.name} page`}
      />

      <h1>{reportTypeEnum?.name}</h1>
      <div className="mt-4">
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <div className="flex items-center gap-6">
            <Button
              variant={'contained'}
              onClick={() => {
                setSubmitted(false)
                setSubmitted(true)
                posthog.capture(
                  `${reportTypeEnum?.name} database export report was run`,
                  {
                    selectedBrokerageIds,
                  }
                )
              }}
              sx={{ height: '2rem' }}
            >
              Generate and Download
            </Button>
          </div>
          <div className="mt-4 flex items-center gap-6">
            <SelectBrokerages
              brokerages={sortBy(profile.brokeragesAsAdmin, 'name')}
              selectedBrokerageIds={selectedBrokerageIds}
              setSelectedBrokerageIds={(newSelectedBrokerageIds) => {
                setSubmitted(false)
                setSelectedBrokerageIds(newSelectedBrokerageIds)
              }}
            />
          </div>
        </LocalizationProvider>
        {submitted ? (
          <DatabaseExportReportCell
            input={{ selectedBrokerageIds, reportType }}
          />
        ) : null}
      </div>
    </>
  )
}

export default DatabaseExportReportPage
