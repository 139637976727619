import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Switch from '@mui/material/Switch'
import type { Brokerage } from 'types/graphql'

const SelectBrokerages = ({
  brokerages,
  selectedBrokerageIds,
  setSelectedBrokerageIds,
}: {
  brokerages: Pick<Brokerage, 'id' | 'name'>[]
  selectedBrokerageIds: string[]
  setSelectedBrokerageIds: (ids: string[]) => void
}) => {
  const handleToggle = (brokerageId: string) => () => {
    const currentIndex = selectedBrokerageIds.indexOf(brokerageId)
    const newSelectedBrokerageIds = [...selectedBrokerageIds]

    if (currentIndex === -1) {
      newSelectedBrokerageIds.push(brokerageId)
    } else {
      newSelectedBrokerageIds.splice(currentIndex, 1)
    }

    setSelectedBrokerageIds(newSelectedBrokerageIds)
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        Select Brokerages
      </AccordionSummary>
      <AccordionDetails>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          subheader={
            <ListSubheader>Select which brokerages to include</ListSubheader>
          }
        >
          {brokerages.map((brokerage) => (
            <ListItem key={brokerage.id}>
              <ListItemText id={brokerage.id} primary={brokerage.name} />
              <Switch
                edge="end"
                onChange={handleToggle(brokerage.id)}
                checked={selectedBrokerageIds.includes(brokerage.id)}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-wifi',
                }}
              />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default SelectBrokerages
