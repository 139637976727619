import { useEffect } from 'react'

import jsonToCsvExport from 'json-to-csv-export'
import { DateTime } from 'luxon'
import type { DatabaseExportReport as DatabaseExportReportType } from 'types/graphql'

const DatabaseExportReport = ({
  data,
  reportType,
}: {
  data: DatabaseExportReportType['data']
  reportType: string
}) => {
  useEffect(() => {
    if (data?.length > 0) {
      const dateStr = DateTime.now().toFormat('yyyy-MM-dd')
      jsonToCsvExport({
        data,
        filename: `${reportType}-report-${dateStr}.csv`,
      })
    } else {
      console.log('No data to export')
    }
  }, [data, reportType])
  return (
    <div>
      <p className="text-md font-bold m-4">Data file has been downloaded</p>
    </div>
  )
}

export default DatabaseExportReport
