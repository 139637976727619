const LoadingIndicator = () => {
  return (
    <img
      src="/images/loading-indictator.gif"
      alt="loading..."
      width="120px"
      height="120px"
      style={{ margin: '40vh auto 40vh auto' }}
      data-testid="loading-indicator"
    />
  )
}

export default LoadingIndicator
