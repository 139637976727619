import { ApprovalStatus as ApprovalStatusPrisma } from '@prisma/client'

export const BuildingTypes: { [key: string]: { name: string } } = {
  detachedHome: {
    name: 'Detached Home',
  },
  townhouse: {
    name: 'Townhouse',
  },
  condo: {
    name: 'Condo/Co-op',
  },
  multiFamily: {
    name: 'Multi-family',
  },
  commercial: {
    name: 'Commercial',
  },
  land: {
    name: 'Land',
  },
  other: {
    name: 'Other',
  },
}

export const NotificationPeriodicity: {
  [key: string]: {
    name: string
    db: string
    index: number
    days: number
  }
} = {
  NEVER: { name: 'Never', db: 'NEVER', index: 0, days: -1 },
  INSTANTLY: { name: 'Instantly', db: 'INSTANTLY', index: 1, days: 0 },
  DAILY: { name: 'Daily Digest', db: 'DAILY', index: 2, days: 1 },
  TWICE_WEEKLY: {
    name: 'Twice Weekly Digest',
    db: 'TWICE_WEEKLY',
    index: 3,
    days: 3,
  },
  WEEKLY: { name: 'Weekly Digest', db: 'WEEKLY', index: 4, days: 7 },
  //MONTHLY: { name: 'Monthly Digest', index: 4, days: 30, },
}

export const notificationCategory: { [key: string]: { key: string } } = {
  LIKE: {
    key: 'like',
  },
  SAVED_SEARCH_MATCH: {
    key: 'saved-search-match',
  },
  MENTION: {
    key: 'mention',
  },
  BROKERAGE_APPROVAL: {
    key: 'brokerage-approval',
  },
  NEW_LISTING: {
    key: 'new-listing',
  },
  NEW_POST: {
    key: 'new-post',
  },
  LISTING_ON_MLS: {
    key: 'listing-found-on-mls',
  },
}

export const PermissionsMapping: {
  [key: string]: {
    brokerage: { name: string }
    share: { name: string }
    level: number
  }
} = {
  // Note: if you add/change something here, check if getPermissionLevel needs to be updated
  ADMIN: {
    brokerage: { name: 'Admin' },
    share: { name: 'Admin' },
    level: 4,
  },
  FULL: {
    brokerage: { name: 'Owner' },
    share: { name: 'Owner' },
    level: 3,
  },
  EDIT: {
    brokerage: { name: 'Manager' },
    share: { name: 'Editor' },
    level: 2,
  },
  READ_ONLY: {
    brokerage: { name: 'Agent' },
    share: { name: 'Read Only' },
    level: 1,
  },
  NONE: {
    brokerage: { name: 'None' },
    share: { name: 'None' },
    level: 0,
  },
}

export const PostPriority: { [key: string]: { value: number } } = {
  MLS: {
    value: 0,
  },
  POST: {
    value: 10,
  },
  OFF_MARKET_LISTING: {
    value: 10,
  },
  BROKERAGE: {
    value: 20,
  },
  ADMIN: {
    value: 30,
  },
}

export const ApprovalStatus: {
  [key: string]: {
    name: string
    db: ApprovalStatusPrisma
  }
} = {
  APPROVED: {
    name: 'Approved',
    db: 'APPROVED' as ApprovalStatusPrisma,
  },
  PENDING: {
    name: 'Pending',
    db: 'PENDING' as ApprovalStatusPrisma,
  },
  REJECTED: {
    name: 'Rejected',
    db: 'REJECTED' as ApprovalStatusPrisma,
  },
}

export const ApprovalPolicy: {
  [key: string]: {
    name: string
    db: string
    defaultListingApprovalStatus: ApprovalStatusPrisma
  }
} = {
  REVIEW: {
    name: 'Manual review before publishing',
    db: 'REVIEW',
    defaultListingApprovalStatus: ApprovalStatus.PENDING.db,
  },
  IMMEDIATE: {
    name: 'Immediately publish when agent publishes',
    db: 'IMMEDIATE',
    defaultListingApprovalStatus: ApprovalStatus.APPROVED.db,
  },
}

export type DatabaseExportReportTypeValue = {
  code: string
  name: string
  description: string
}

export const DatabaseExportReportType: Record<
  string,
  DatabaseExportReportTypeValue
> = {
  LISTINGS: {
    code: 'listings',
    name: 'Listings Report',
    description:
      'All private listings associated with the selected brokerage(s), including the agent and market center.',
  },
  AGENTS: {
    code: 'agents',
    name: 'Agents Report',
    description:
      'All agents associated with the selected brokerage(s), including their market center and the count of their listings and saved searches.',
  },
  CLIENTS: {
    code: 'count-clients',
    name: 'Count Clients Report',
    description: 'Count of clients for agents at selected brokerage(s)',
  },
  SAVED_SEARCHES: {
    code: 'count-saved-searches',
    name: 'Count Saved Searches Report',
    description: 'Count of saved searches for agents at selected brokerage(s)',
  },
  LISTINGS_LEGACY: {
    code: 'listings-legacy',
    name: 'Listings (Legacy) Report',
    description:
      'All private listings associated with the selected brokerage(s), including a broad array of additional data.',
  },
}
